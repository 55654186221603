import { useEffect, useState } from 'react';
import { API } from '@api';


export const usePromoData = (slug) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        API.getPromoData(slug).then((data) => setData(data));
    }, [setData]);

    return data
};
