import React, { useContext } from 'react';
import { getMessagesByLocale } from '../index';
import { IntlProvider } from 'react-intl';
import { DataContext } from '../../hocs';


export const TranslateProvider = ({ children }) => {
    const domainData = useContext(DataContext);

    return (
        <IntlProvider locale={domainData.lang} messages={getMessagesByLocale(domainData.lang)} defaultLocale="ru">
            {children}
        </IntlProvider>
    );
};

