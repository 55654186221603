import React from 'react';
import { Helmet } from 'react-helmet';
import { useTrackers } from '../../hooks';


export const WithHelmetMetriks = ({ children, trackers }) => {
    const scripts = useTrackers(trackers)

    return (
        <Helmet>
            {scripts.ya && <script type="text/javascript">{scripts.ya}</script>}
            {scripts.ga && <script type="text/javascript">{scripts.ga}</script>}
            {scripts.mailru && <script type="text/javascript">{scripts.mailru}</script>}
            {scripts.fb && <script type="text/javascript">{scripts.fb}</script>}
            {scripts.vk && <script type="text/javascript">{scripts.vk}</script>}
            {scripts.mgid && <script type="text/javascript">{scripts.mgid}</script>}
            {scripts.google_ads && <script async src={`https://www.googletagmanager.com/gtag/js?id=${scripts.google_ads.pixel_id}`}/>}
            {scripts.google_ads?.code && <script>{scripts.google_ads.code}</script>}
            {scripts.google_ads?.code2 && <script>{scripts.google_ads.code2}</script>}
            {scripts.google_tag_manager && <noscript>{`<iframe src={'www.googletagmanager.com/ns.html?id=${scripts.google_tag_manager.tag}'} height='0' width='0' style='display:none;visibility:hidden'/>`}</noscript>}
            {scripts.google_tag_manager && <script type="text/javascript">{scripts.google_tag_manager.code}</script>}
            {scripts.tiktok && <script type="text/javascript">{scripts.tiktok}</script>}
            {scripts.bigoads && <script type="text/javascript">{scripts.bigoads.code}</script>}
            {scripts.bigoads && <script async type="text/javascript" src={`https://api.imotech.video/ad/events.js?pixel_id=${scripts.bigoads.pixel_id}`}/>}

            {children}
        </Helmet>
    )
};
