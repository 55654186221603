import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './assets/scss/style.scss'
import { DataProvider } from './hocs'
import { TranslateProvider } from './translations/TranslateProvider/TranslateProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<BrowserRouter>
		<DataProvider>
			<TranslateProvider>
				<App />
			</TranslateProvider>
		</DataProvider>
	</BrowserRouter>
)
