import React, { useEffect } from 'react';
import { useLocation } from 'react-router';


export const useUrlHash = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, []);
};
