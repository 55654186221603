import ru from './ru.json'
import kz from './kz.json'
import en from './en.json'
import am from './am.json'
import kg from './kg.json'
import uz from './uz.json'

export const getMessagesByLocale = (locale) => {
    switch (locale) {
    case 'ru':
        return ru
    case 'kz':
        return kz
    case 'am':
        return am
    case 'kg':
        return kg
    case 'uz':
        return uz
    case 'en':
        return en
    default:
        return ru
    }
}
