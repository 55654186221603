import { useEffect, useState } from 'react';
import { API } from '@api';
import { useLocation } from 'react-router';


export const usePromoTrackers = () => {
    const [data, setData] = useState({});
    const { search } = useLocation();

    useEffect(() => {
        search &&
        API.getPromoTrackers(search).then((data) => {
            setData(data);
        });
    }, []);

    return data.trackers
};
