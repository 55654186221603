import { useEffect, useState } from 'react';
import { API } from '@api';

export const useInfo = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        API.getInfo().then((data) => setData(data));
    }, [setData]);

    return data
};
