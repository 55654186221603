import React, { createContext, useEffect, useState } from 'react'

import { useInfo } from '../../hooks/useInfo'

import { renameLangFlag } from '../../helpers/renameLangFlag'
import { useProductsData } from '../../hooks'

let fullDomainName = window.location.hostname
const allLangs = ['kz', 'kg', 'uz', 'by']

export const DataContext = createContext({
	langs: allLangs,
	lang: 'ru',
	setLang: () => {},
	isLangChange: false,
	setIsLangChange: () => {},
	domainZone: 'com',
	setDomainZone: () => {},
	categories: [],
	price: 1,
	priceFull: 294,
	products: [],
	loading: false,
	currency: 'RUB',
	firstCheckbox: 'Some text for checkbox - 1',
	secondCheckbox: 'Some text for checkbox - 2'
})

export const DataProvider = ({ children }) => {
	const [lang, setLang] = useState('ru')
	const [langs, setLangs] = useState(allLangs)
	const [isLangChange, setIsLangChange] = useState(false)
	const [domainZone, setDomainZone] = useState('com')
	const data = useInfo()
	const { products, loading } = useProductsData()
	let categories = data?.categories
	let price = data?.price
	let priceFull = data?.priceFull
	let currency = data?.currency
	let firstCheckbox = data?.firstCheckbox
	let secondCheckbox = data?.secondCheckbox

	useEffect(() => {
		if (fullDomainName.includes('skilldeposit.kg')) {
			setDomainZone('kg')
		} else if (fullDomainName.includes('skilldeposit.kz')) {
			setDomainZone('kz')
		} else if (fullDomainName.includes('skilldeposit.am')) {
			setDomainZone('am')
		} else if (fullDomainName.includes('skilldeposit.uz')) {
			setDomainZone('uz')
		} else if (fullDomainName.includes('skilldeposit.tj')) {
			setDomainZone('tj')
		} else if (fullDomainName.includes('skilldeposit.ge')) {
			setDomainZone('ge')
		} else if (fullDomainName.includes('skilldeposit.by')) {
			setDomainZone('by')
		} else if (fullDomainName.includes('skilldeposit.az')) {
			setDomainZone('az')
		} else {
			setDomainZone('com')
		}
	}, [])

	useEffect(() => {
		let domain = fullDomainName.split('.').slice(1).join('.')
		if (lang !== domain && isLangChange) {
			setIsLangChange(false)
			window.location.href =
				'https://skilldeposit.' +
				`${lang === 'ru' ? 'com' : lang}` +
				window.location.pathname
		}
	}, [lang, isLangChange])

	return (
		<DataContext.Provider
			value={{
				lang,
				setLang,
				langs,
				isLangChange,
				setIsLangChange,
				domainZone,
				setDomainZone,
				categories,
				price,
				priceFull,
				products,
				loading,
				currency,
				firstCheckbox,
				secondCheckbox
			}}
		>
			{children}
		</DataContext.Provider>
	)
}
