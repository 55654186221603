import { useEffect, useState } from 'react'

import { API } from '../../api'

export const useProductsData = func => {
	const [products, setProducts] = useState(null)
	const [loading, setLoading] = useState(null)
	const [error, setError] = useState(null)

	useEffect(() => {
		setLoading('loading...')
		setProducts(null)
		setError(null)
		API.getProducts()
			.then(res => {
				setLoading(false)
				res && setProducts(res)
			})
			.catch(err => {
				setLoading(false)
				setError('An error occurred. Awkward..')
			})
	}, [func])

	return { products, loading, error }
}
