import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { DataContext } from '../../hocs'

export const useCategories = products => {
	const [categoriesSlug, setCategoriesSlug] = useState([])
	const [categoriesName, setCategoriesName] = useState([])

	const intl = useIntl()
	const { lang } = useContext(DataContext)

	useEffect(() => {
		if (products && products.length !== 0) {
			let filtered = products.filter(item =>
				item.availableLanguages.includes(lang)
			)
			let setSlug = new Set()
			let setName = new Set()
			setSlug.add('all')
			setName.add(intl.formatMessage({ id: 'filter.all' }))
			filtered.forEach(item => {
				if (item.categories[0] && item.categories[0].slug !== '18-plus') {
					setSlug.add(item.categories[0].slug)
					setName.add(
						intl.formatMessage({ id: `filter.${item.categories[0].slug}` })
					)
				}
			})
			let arrSlug = Array.from(setSlug)
			let arrName = Array.from(setName)
			setCategoriesSlug(arrSlug)
			setCategoriesName(arrName)
		}
	}, [products, lang])

	return [categoriesSlug, categoriesName]
}
