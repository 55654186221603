import axios from 'axios';
import { debounce } from '../helpers/debounce';
import { checkEmailValidity } from '../helpers/checkEmailValidity';


var instance;
function getCurrentDomainZone(){
    let hostname = window.location.hostname;
    if(hostname.includes("skilldeposit.kg")) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH_KG,
            withCredentials: true,
        });
        return instance;
    } 
    else if(hostname.includes("skilldeposit.am")) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH_AM,
            withCredentials: true,
        });
        return instance;
    } 
    else if(hostname.includes("skilldeposit.kz")) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH_KZ,
            withCredentials: true,
        });
        return instance;
    } 
    else if(hostname.includes("skilldeposit.uz")) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH_UZ,
            withCredentials: true,
        });
        return instance;
    } 
    else if(hostname.includes("skilldeposit.tj")) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH_TJ,
            withCredentials: true,
        });
        return instance;
    } 
    else if(hostname.includes("skilldeposit.ge")) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH_GE,
            withCredentials: true,
        });
        return instance;
    } 
    else if(hostname.includes("skilldeposit.by")) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH_BY,
            withCredentials: true,
        });
        return instance;
    } 
    else if(hostname.includes("skilldeposit.az")) {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH_AZ,
            withCredentials: true,
        });
        return instance;
    } 
    else {
        instance = axios.create({
            baseURL: process.env.REACT_APP_REQUEST_PATH,
            withCredentials: true,
        });
        return instance;
    }
}
getCurrentDomainZone();
export const API = {
    auth: (number) => {
        return instance
            .get(`user/auth/${number}`)
            .then(response => response.data);
    },
    getAuthData: () => {
        return instance
            .get('/user/profile')
            .then((response) => response.data)
            .catch(() => null)
    },
    logout: () => {
        return instance
            .post('/user/logout')
            .then((response) => response.data)
            .catch(() => null)
    },
    getSuccessData: (number) => {
        return instance
            .get(`order/info/${number}`)
            .then(response => response.data);
    },
    sendOrder: (data) => {
        return instance
            .post('order', { ...data },
                {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
            .then(response => response.data)
    },
    getInfo: async () => {
        return instance.get('products/info').then((response) => response.data);
    },
    getProducts: async () => {
        return instance.get('products').then(response => response.data.sort(() => Math.random() - 0.5));
    },
    getPromoData: async (slug) => {
        return instance.get(`promo/${slug}`).then(response => response.data);
    },
    sendEmail: (email) => {
        debounce(function () {
            email.replace(/^(0|\+44) */, '');
            email.toLowerCase();
            const { hostname } = window.location;
            const apiPath = window.location.protocol + `//${hostname}` + (hostname === 'localhost' ? ':' + window.location.port : '');

            if (checkEmailValidity(email)) {
                fetch(apiPath + `/email?email=${email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                }).catch((e) => {throw new Error(e)});
            }
        }, 500)()
    },
    getStatus: (invoice) => {
        return instance.get(`order/status/${invoice}`).then(response => response.data);
    },
    getOffStatusFlag: (invoice) => {
        return instance.get(`order/status/${invoice}?off`).then(response => response.data)
    },
    getPromoTrackers: async (query) => {
        return instance.get(`promo${query}`).then(response => response.data);
    },
}
