import { useMediaQuery } from '@uidotdev/usehooks'

import { variables } from '@/styles'

export const useMedia = () => {
	const sm = useMediaQuery(`(max-width: ${variables.breakpoints.sm}px)`)
	const md = useMediaQuery(`(max-width: ${variables.breakpoints.md}px)`)
	const lg = useMediaQuery(`(max-width: ${variables.breakpoints.lg}px)`)
	const xl = useMediaQuery(`(max-width: ${variables.breakpoints.xl}px)`)

	return {
		sm,
		md,
		lg,
		xl
	}
}
