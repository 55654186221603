const breakpoints = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200
}

const variables = {
	breakpoints
}

export default variables
